/* Emojimart */
.emoji-mart {
  width: 100% !important;
  background-color: var(--background-color) !important;
  border: var(--background-color) !important;
}

.emoji-mart-category-label span {
  color: var(--text-color-passive) !important;
  background-color: var(--background-color) !important;
}

.emoji-mart-search input {
  color: var(--text-color) !important;
  border-color: var(--background-color-passive) !important;
  background-color: var(--background-color) !important;
}

.emoji-mart-bar {
  border-color: var(--background-color-passive) !important;
}

.emoji-mart-anchor {
  color: var(--background-color-passive) !important;
}

.emoji-mart-anchor-icon {
  justify-content: center;
  display: flex;
}

.emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: var(--text-color-passive) !important;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  background-color: var(--text-color-passive) !important;
  height: 2px !important;
}
