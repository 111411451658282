.codex-editor {
  z-index: 10;
}
@media (min-width: 768px) {
  .codex-editor {
    z-index: 30;
  }
}

.ce-email-signup {
  border: 1px dashed var(--border-color);
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 4px;
  max-width: 360px;
}

.ce-email-signup__input {
  margin-bottom: 0.5rem;
  border-radius: 2px;
  border: 1px solid var(--border-color);
  padding: 3px 8px;
  font-size: 0.75rem;
  text-align: center;
}

.ce-email-signup__button {
  border-radius: 2px;
  background-color: var(--text-color-passive);
  color: var(--background-color);
  width: 100%;
  padding: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ce-email-signup__button:hover {
  background-color: var(--text-color);
}

.ce-paragraph.cdx-block.ce-paragraph--asCallout {
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 4px;
}

.cdx-input.cdx-quote__text {
  border: none;
  border-left: 3px solid var(--text-color);
  border-radius: 0px;
  padding: 0px 0px 0px 10px;
  min-height: 0px;
  font-style: italic;
  color: var(--text-color-passive);
}

.ce-toc {
  padding: 1rem 0px;
}
.ce-toc a {
  opacity: 0.75;
  line-height: 1.6em;
}
.ce-toc ul {
  padding-left: 0.5rem;
}
.ce-toc ul li {
  padding-left: 0.5rem;
}
.ce-toc li ul {
  padding-left: 0.5rem;
}
.ce-toc > ul {
  padding-left: 0rem;
}
.ce-toc > ul > li {
  padding-left: 0rem;
}

.cdx-marker {
  background: rgb(250 204 21 / 60%);
}

.tc-wrap {
  --color-border: var(--border-color) !important;
  --color-background: var(--background-color-passive) !important;
  --color-text-secondary: var(--text-color) !important;
}

.ct__content {
  background: var(--background-color-passive) !important;
  color: inherit;
}

.ce-inline-tool-input {
  font-size: 10px;
  border-top: none;
  padding-top: 0px;
}

.ce-toolbar__settings-btn {
  color: inherit;
}

.ce-block--selected .ce-block__content,
.ce-inline-toolbar,
.ce-inline-toolbar__dropdown,
.ce-inline-tool,
.codex-editor--narrow .ce-toolbox,
.ce-conversion-toolbar,
.ce-settings,
.ce-settings__button,
.ce-toolbar,
.tc-toolbox__toggler,
.cdx-button {
  background: var(--background-color-passive) !important;
  color: inherit;
  border: none;
}

.tc-popover__item .tc-popover__item-icon {
  color: var(--background-color);
}
.tc-popover__item:last-of-type .tc-popover__item-icon {
  background: var(--background-color-passive) !important;
  color: var(--background-passive);
}

.ce-inline-tool,
.ce-conversion-toolbar__label,
.ce-toolbox__button,
.cdx-settings-button,
.ce-toolbar__plus {
  color: inherit;
  border: none;
}

.cdx-settings-button:hover,
.ce-settings__button:hover,
.ce-toolbox__button--active,
.ce-toolbox__button:hover,
.cdx-button:hover,
.ce-inline-toolbar__dropdown:hover,
.ce-toolbar__plus:hover,
.ce-toolbar__settings-btn:hover,
.ce-conversion-tool:hover,
.tc-toolbox__toggler:hover,
.ce-inline-tool:hover {
  background-color: var(--background-color) !important;
  color: inherit;
  border: none;
}

.tc-popover--opened {
  border: none !important;
  background-color: var(--background-color-passive) !important;
}

.ce-conversion-tool--focused {
  background-color: var(--background-color-passive) !important;
}

.link-tool__content {
  border: none;
}
.link-tool__content.link-tool__content--rendered {
  padding: 1rem;
  background-color: var(--background-color-passive) !important;
}
.link-tool__content .link-tool__image {
  aspect-ratio: 120/63;
  width: auto;
  height: 80px;
}

.link-tool__content .link-tool__title {
  color: var(--text-color) !important;
  margin-bottom: 0px;
}
.link-tool__content .link-tool__anchor,
.link-tool__content .link-tool__description {
  color: var(--text-color-passive) !important;
}
.link-tool__content .link-tool__anchor {
  font-size: 0.75rem;
  font-weight: 500;
}

.ce-code__textarea.cdx-input {
  font-family: ui-monospace, Menlo, Monaco, Consolas, Courier New, monospace !important;
  background-color: var(--background-color-passive) !important;
  color: var(--text-color);
  border: none;
}

img.image-tool__image-picture {
  margin: auto;
}

.cdx-input.image-tool__caption {
  border-radius: 0;
  border: 0;
  padding: 0;
  font-style: italic;
  color: var(--text-color-passive);

}
