@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --darkest: #262626; /* bg-neutral-900 */
  --dark: #404040; /* bg-neutral-800 */
  --light: #e5e5e5; /* bg-neutral-300 */
  --lightest: #f5f5f5; /* bg-neutral-200 */

  --background-color: var(--lightest);
  --background-color-passive: var(--light);
  --border-color: var(--light);
  --text-color: var(--darkest);
  --text-color-passive: var(--dark);

  --color-brown: #977669;
  --color-orange: #ea580c;
  --color-yellow: #ca8a04;
  --color-green: #15803d;
  --color-blue: #2563eb;
  --color-purple: #9333ea;
  --color-pink: #db2777;
  --color-red: #ef4444;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: var(--darkest);
    --background-color-passive: var(--dark);
    --border-color: var(--dark);
    --text-color: var(--lightest);
    --text-color-passive: var(--light);

    --color-brown: #d2bab0;
    --color-orange: #fb923c;
    --color-yellow: #facc15;
    --color-green: #4ade80;
    --color-blue: #60a5fa;
    --color-purple: #c084fc;
    --color-pink: #f472b6;
    --color-red: #f87171;
  }
}

code {
  background: var(--text-color-passive) !important;
  color: var(--background-color) !important;
  padding: 2px 4px;
  border-radius: 5px;
  margin: 0 1px;
  font-family: inherit;
  font-size: 0.86em;
  font-weight: 500;
}

#__next svg,
.ReactModalPortal svg {
  fill: var(--text-color);
  color: var(--text-color);
}

.nav-blur {
  background-color: #1f2937c2;
  z-index: 1;
  backdrop-filter: blur(2px);
}

.content {
  position: relative;
  max-width: 650px;
}

/* Global */
html,
body {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  border-color: var(--border-color);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

::selection {
  background: var(--border-color);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-color-passive);
  opacity: 1; /* Firefox */
  font-style: italic;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-color-passive);
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-color-passive);
  font-style: italic;
}

.ConnectButtonWrapper:first-child {
  display: inline-block;
}

h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

h2 {
  font-size: 1.25rem;
  font-weight: 500;
}

h3 {
  font-size: 1.125rem;
  font-weight: 500;
}

p {
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    font-weight: 500;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

/* Editor.js */
input,
textarea {
  background-color: transparent;
  width: 100%;
  color: var(--text-color);
}

input:disabled,
textarea:disabled {
  opacity: 1;
}

/* Button */
.Button {
  box-shadow: inset 0 0 6px 0px var(--text-color);
  transition: all 0.25s cubic-bezier(0.42, 0, 0, 1.05);
  border-radius: 9999px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap;
}
.Button[disabled] {
  opacity: 0.6;
  pointer-events: none;
}
.Button:hover {
  box-shadow: inset 0 0 20px 0px var(--text-color);
}

/* Toast */
.toast {
  background-color: var(--background-color-passive) !important;
  color: var(--text-color) !important;
  font-size: 12px;
  font-weight: 600;
}

/* Loader */
.loader svg {
  width: 14px;
  height: 14px;
  display: inline;
  -webkit-animation: spin 2s ease-in-out infinite;
  -moz-animation: spin 2s ease-in-out infinite;
  animation: spin 2s ease-in-out infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(64, 64, 64, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(64, 64, 64, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(64, 64, 64, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(64, 64, 64, 1);
    box-shadow: 0 0 0 0 rgba(64, 64, 64, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(64, 64, 64, 0);
    box-shadow: 0 0 0 10px rgba(64, 64, 64, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(64, 64, 64, 0);
    box-shadow: 0 0 0 0 rgba(64, 64, 64, 0);
  }
}
